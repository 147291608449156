import { AiIcon } from '@copilot/common/components/ai/aiIcon/aiIcon';
import styles from './aiCircleIcon.module.less';

/**
 * Circle icon for AI features
 * @returns
 */
export function AiCircleIcon() {
	return (
		<div className={styles.aiCircleIconOuter}>
			<div className={styles.aiCircleIconInner}>
				<AiIcon noSeparator noSpace />
			</div>
		</div>
	);
}
