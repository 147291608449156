import { Redirect, useParams } from 'react-router';
import { useImpersonation } from '../adminHub/useImpersonation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { RootState } from '@copilot/common/store/reducers';
import { isNil } from 'lodash';
import { useSearchParams } from '@copilot/common/hooks/common';
import useTeamsPageSideBarContext from '@copilot/teams/src/useTeamsPageSideBarContext';

type ImpersonationRedirectParams = Readonly<{
	/**
	 * The impersonator's user id
	 */
	adminUserId: string;
	/**
	 * The impersonatee's user id
	 */
	impersonateeId: string;
}>;

type ImpersonationRedirectSearchParams = Readonly<{
	/**
	 * Path to redirect user to on impersonation success
	 */
	path?: string;
}>;

/**
 * Component to redirect user to the impersonation user journey
 * @constructor
 */
export default function ImpersonationRedirect() {
	const { currentAccount, isLoadingCurrentAccount } = useTeamsPageSideBarContext();
	const isAccountDashboardEnabled = currentAccount?.isResellerAccount ?? false;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	const isImpersonating = !isNil(activeImpersonator);
	const canImpersonate =
		(isAccountDashboardEnabled || activeMember?.isSysAdmin) && !isImpersonating;

	const { adminUserId, impersonateeId } = useParams<ImpersonationRedirectParams>();
	const [searchParams] = useSearchParams<ImpersonationRedirectSearchParams>();
	const [onImpersonate] = useImpersonation();

	useEffect(() => {
		if (canImpersonate) onImpersonate(adminUserId, impersonateeId, searchParams.path);
	}, [canImpersonate]);

	if (isImpersonating) return <Redirect to={searchParams.path ?? '/'}>Loading ...</Redirect>;
	if (!isLoadingCurrentAccount && !canImpersonate) return <>Sorry, you do not have access to this part of the application.</>;

	return <>Impersonation in progress...</>;
}
