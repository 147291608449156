import { CommunicationStyle } from '../../common/personalizedInsightsV2Types';
import { getInsightsLookup } from '../../common/personalizedInsightsV2CopyData';
import { PersonalizedInsightsData, TemplateCategory } from './types';

/**
 * Getter for the insights body copy
 * @param insights
 * @returns
 */
export function getInsightsCopy(style: CommunicationStyle): PersonalizedInsightsData {
	return getInsightsLookup()[style];
}

/**
 * Drop down options for the template section
 * @returns
 */
export function getTemplateOptions(): { value: TemplateCategory; label: string }[] {
	return [
		{ value: 'postConnection', label: 'Post-Connection Engagement' },
		{ value: 'nurturing', label: 'Nurturing the Prospect' },
		{ value: 'meetings', label: 'Meeting Request' },
		{ value: 'objections', label: 'Objection Responses' },
	];
}
