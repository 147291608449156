import { useContext, useEffect, useState } from 'react';
import ContactDrawerV2Context from '../../context/contactDrawerV2Context';
import { useBookMeeting } from '../../../wrappers/contact/data';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import useRemindersV2 from '../../../wrappers/contact/useReminders/useRemindersV2';
import moment, { Moment } from 'moment';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { AIFeatureManager, ContactManager } from '@copilot/data';
import { TermsOfUse } from '@copilot/data/responses/interface';
import {
	handleOpenPersonalizedInsights,
	parseCampaignHistory,
} from '../../../wrappers/contact/informationPanel/util';
import modalManager from '@copilot/common/utils/modalManager';
import { useSelector } from 'react-redux';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';

/**
 * Business logic for the contact drawer info panel, handles all data management and crud flows for the info panel
 * @returns
 */
export function useInfoPanelV2() {
	const {
		conversation,
		isLoading,
		targetProfileId,
		targetAlias,
		isTargetOpenProfile,
		orgMemberId,
		orgContact,
		activeMember,
		activeAdmin,
		campaignConnection,
		contactData: {
			campaignConnections,
			isContactCompanyUpdating,
			isContactEmailUpdating,
			isContactTitleUpdating,
			isContactPhoneNumberUpdating,
			contact,
			onContactCompanyUpdate,
			onContactEmailUpdate,
			onContactPhoneNumberUpdate,
			onContactTitleUpdate,
		},
		onUpdateCurrentConversation,
		onChangeSelectedOrgMember,
		onMeetingBookedUpdated: externalOnMeetingBookedUpdated,
	} = useContext(ContactDrawerV2Context);

	//loading state for reminder  submission
	const [isReminderLoading, setIsReminderLoading] = useState<boolean>(false);

	//tag lookup state, tags are determined on a per connection basis, so a given contact may have different tag sets we need to render depending on which conversation is being viewed
	const [tagMap, setTagMap] = useState<Record<string, string[]>>({});

	const [isPersonalizedInsightsLoading, setIsPersonalizedInsightsLoading] =
		useState<boolean>(false);

	//exhaustive list of all defined tags for the org
	const orgTags = useSelector(getAllOrgTags);

	//whenever the orgContact changes, update the tag map
	useEffect(() => {
		if (isNil(orgContact)) {
			setTagMap({});
		} else {
			const newTagMap: Record<string, string[]> = {};
			orgContact.tags.forEach((tag) => {
				const existingTags = newTagMap[tag.connectionId] ?? [];
				newTagMap[tag.connectionId] = [...existingTags, tag.name];
			});
			setTagMap(newTagMap);
		}
	}, [orgContact]);

	// booked meeting mutation callbacks and state
	const [handleMeetingUpdate, bookMeetingMutationResult] = useBookMeeting(
		true,
		campaignConnection?.id,
		() => {}
	);

	function onMeetingBookedUpdated(isBooked: boolean) {
		if (isNil(campaignConnection)) return;
		externalOnMeetingBookedUpdated?.(campaignConnection.id, isBooked);
		handleMeetingUpdate(isBooked);
	}

	//reminder update callbacks
	const { setReminderClick, setReminder, cancelReminder } = useRemindersV2(
		undefined,
		updateConversationReminderDate
	);

	//AI feature terms of use state
	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(TermsOfUse.AIFeature, () =>
		AIFeatureManager.acceptTermsOfUse()
	);

	// updater for client-side reminder state (assumes optimistic update)
	function updateConversationReminderDate(reminderDate: string | undefined) {
		onUpdateCurrentConversation({
			snoozedDateTime: reminderDate,
			isSnoozed: !isNil(reminderDate),
		});
	}

	//reminder update handlers
	function onUpdateReminder(date: Moment, isNewReminder: boolean) {
		if (isNil(conversation)) return;
		setIsReminderLoading(true);
		try {
			setReminder(conversation.id, date, isNewReminder);
		} catch (_) {
			notificationManager.showErrorNotification({
				message: 'There was an issue updating your reminder.',
			});
		}
		setIsReminderLoading(false);
	}

	function onCancelReminder() {
		if (isNil(conversation)) return;
		setIsReminderLoading(true);
		try {
			cancelReminder(conversation.id);
		} catch (_) {
			notificationManager.showErrorNotification({
				message: 'There was an issue cancelling your reminder.',
			});
		}
		setIsReminderLoading(false);
	}

	//personalized insights handlers
	function onOpenPersonalizedInsights(): Promise<void> {
		setIsPersonalizedInsightsLoading(true);
		return handleOpenPersonalizedInsights(targetProfileId ?? '').finally(() => {
			setIsPersonalizedInsightsLoading(false);
		});
	}

	function onPersonalizedInsightsNotAvailable() {
		modalManager.openAIFeatureTermsOfUseModal({
			onSubmit: () => void onOpenPersonalizedInsights(),
		});
	}

	//tag handlers
	function onUpdateDisplayedTags(tags: string[]) {
		if (isNil(campaignConnection)) return;
		setTagMap({ ...tagMap, [campaignConnection.id]: tags });
	}

	async function onSaveTags(tags: string[]) {
		// updateProspectDrawerTracking({ buttonClicked: 'Save Tags' });
		if (isNil(orgContact) || isNil(orgMemberId) || isNil(campaignConnection)) return;

		const csOrgId = !isNil(activeAdmin) ? orgMemberId : null;
		onUpdateDisplayedTags(
			(
				await ContactManager.updateConnectionTags(
					[campaignConnection.id],
					tags,
					csOrgId,
					true
				)
			).map((t) => t.name)
		);
	}

	return {
		// common data/state
		orgMemberId,
		isLoading,
		conversation,
		contact,
		campaignConnections,
		orgContact,
		// Header data
		onChangeSelectedOrgMember,
		isTargetOpenProfile,
		targetAlias,
		// Pipeline data
		hasMessaged: !isEmpty(conversation?.sentMessages),
		hasReplied: !isEmpty(conversation?.receivedMessages),
		hasBooked:
			bookMeetingMutationResult?.data?.updateConnectionMeeting?.meeting?.booked ??
			!isNil(campaignConnection?.meeting?.bookedDate) ??
			false,
		// Meeting data
		isLoadingMeeting: bookMeetingMutationResult?.loading ?? false,
		onMeetingBookedUpdated,
		// Reminder data
		reminderDate: conversation?.snoozedDateTime
			? moment(conversation.snoozedDateTime)
			: undefined,
		isReminderLoading,
		onUpdateReminder,
		onCancelReminder,
		setReminderClick,
		//personalized insights data
		hasAcceptedAITermsOfUse,
		isPersonalizedInsightsLoading: isPersonalizedInsightsLoading || isEmpty(targetProfileId),
		onOpenPersonalizedInsights,
		onPersonalizedInsightsNotAvailable,
		//contact editing data
		contactInfoProps: !isNil(contact)
			? {
					contactCompany: {
						value: contact.company,
						isLoading: isContactCompanyUpdating,
						onUpdate: (val: string) => {
							onContactCompanyUpdate?.(val);
						},
					},
					contactEmail: {
						value: contact.email,
						isLoading: isContactEmailUpdating,
						onUpdate: (val: string) => {
							onContactEmailUpdate?.(val);
						},
					},
					contactTitle: {
						value: contact.title,
						isLoading: isContactTitleUpdating,
						onUpdate: (val: string) => {
							onContactTitleUpdate?.(val);
						},
					},
					contactPhoneNumber: {
						value: contact.phoneNumber,
						isLoading: isContactPhoneNumberUpdating,
						onUpdate: (val: string) => {
							onContactPhoneNumberUpdate?.(val);
						},
					},
			  }
			: undefined,
		//tag data
		isOwner:
			(!isNil(activeMember) && activeMember.id == orgMemberId) ||
			activeMember?.isOrgAdmin ||
			!isNil(activeAdmin),
		availableTags: orgTags.data.map((t) => t.name),
		connectionTags: tagMap[String(campaignConnection?.id)] ?? [],
		onSaveTags,
		onUpdateDisplayedTags,
		//campaign history data
		isCampaignHistoryVisible: isNil(activeAdmin), //apparently cs cannot view campaign history?
		campaignHistory:
			isNil(campaignConnections) || isNil(orgMemberId)
				? []
				: parseCampaignHistory(campaignConnections, orgMemberId),
	};
}
