import { CommunicationStyle } from '../../../common/personalizedInsightsV2Types';

/**
 * Type representing the header content contained in the lookup
 */
type TakeAwaysContent = {
	title: string;
	takeAways: string[];
};

/**
 * Get the header content based on communication style
 * @param insights
 * @returns
 */
export function getTakeAwaysContent(commStyle: CommunicationStyle): TakeAwaysContent {
	return getHeaderLookup()[commStyle];
}

/**
 * Get the lookup for the header content
 * @returns
 */
function getHeaderLookup(): { [key in CommunicationStyle]: TakeAwaysContent } {
	return {
		director: {
			title: 'Director',
			takeAways: [
				'Direct, efficient, and fact-based. Use clear agendas and proposals for quick decision-making.',
				'Avoid small talk and fluff. Focus on quick, data-backed rebuttals to objections.',
				'Be prompt in follow-ups, use bullet points for key steps, seek confirmation.',
			],
		},
		relator: {
			title: 'Relator',
			takeAways: [
				'Approach authentically with context; explain in detail and discuss long-term plans.',
				"Don't be pushy or impersonal; ensure logical progression in conversations.",
				'Include a genuine thank-you and resources showing understanding of their needs in follow-ups.',
			],
		},
		socializer: {
			title: 'Socializer',
			takeAways: [
				'Start with rapport-building; keep the atmosphere energetic and interactive with visuals and dialogue.',
				'Avoid low energy and too many details; focus on discussion and relationship-building.',
				'Promptly follow up in a friendly tone, share successes, and invite to events.',
			],
		},
		thinker: {
			title: 'Thinker',
			takeAways: [
				'Focus on data-driven, logical conversations with room for detail; provide proof through demos.',
				'Avoid haste and emotion; offer comprehensive, specific information.',
				'Provide meeting minutes, next steps checklist, and additional data for follow-up clarifications.',
			],
		},
	};
}
