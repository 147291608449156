import useTeamsPageSideBarContext from '@copilot/teams/src/useTeamsPageSideBarContext';
import MeetingsBookedPage from './meetingsBookedPage';
import useMeetingsBookedData from './hooks/useMeetingsBookedData';
import MeetingsBookedContext from './context/meetingsBookedContext';
import { DEFAULT_MEETINGS_BOOKED_CONTEXT } from './context/constants';
import isNil from 'lodash/isNil';
import { Meeting, MeetingsBookedContactDrawerSelectionData } from './types';
import { useEffect, useState } from 'react';
import { ContactManager, InboxManager } from '@copilot/data';
import ContactDrawerV2Smart from '@copilot/common/components/drawer/contactDrawerV2/contactDrawerV2Smart';
import notificationManager from '@copilot/common/utils/notificationManager';
import { AI_DETECTED_TAB_KEY, MeetingsBookedTabKey, RolesWithTeamMemberAccess } from './constants';
import partial from 'lodash/partial';

/**
 * Smart component for managing meeting page state will be further broken down in follow up PRs
 * @returns
 */
export default function MeetingsBookedPageSmart() {
	const { onRefreshMeetingsBookedCount } = useTeamsPageSideBarContext();

	useEffect(() => {
		onRefreshMeetingsBookedCount();
	}, []);
	const {
		campaignOptions,
		teamMemberOptions,
		selectedCampaigns,
		selectedTeamMembers,
		onClearFilters,
		onLoadPage,
		onCampaignFilterChange,
		onTeamMemberFilterChange,
		aiDetectedMeetings,
		confirmedMeetings,
		onViewPersonalizedInsights,
		onUpdatePageSize,
		confirmedPageSize,
		detectedPageSize,
		isLoadingConfirmed,
		isLoadingDetected,
		isInitializingData,
		isClearFiltersEnabled,
		onUpdateDateRange,
		selectedDateRange,
		activeMember,
		onUpdateDetectedMeeting,
		onMarkMeetingUpdated,
		onUpdateOnContactDrawerClose,
	} = useMeetingsBookedData();

	const [openMeetingData, setOpenMeetingData] = useState<
		MeetingsBookedContactDrawerSelectionData | undefined
	>(undefined);

	function onGoToThread(idx: number) {
		const tabKey = openMeetingData?.tabKey;
		const targetContent =
			tabKey === AI_DETECTED_TAB_KEY ? aiDetectedMeetings.content : confirmedMeetings.content;

		if (isNil(tabKey) || idx >= targetContent.length) return;
		onViewConversation(tabKey, targetContent[idx], idx);
	}

	async function onViewConversation(tabKey: MeetingsBookedTabKey, meeting: Meeting, idx: number) {
		const totalMeetingsInTab =
			tabKey === AI_DETECTED_TAB_KEY
				? aiDetectedMeetings.content.length
				: confirmedMeetings.content.length;
		setOpenMeetingData({ tabKey, meeting, currIdx: idx, totalMeetingsInTab, isLoading: true });

		try {
			const [{ thread: conversation, targetProfile }, orgContact] = await Promise.all([
				InboxManager.getConversationAndTargetProfileForOrgContactAndMember(
					meeting.contactId,
					meeting.orgMemberId
				),
				ContactManager.getContact(meeting.contactId),
			]);

			setOpenMeetingData({
				meeting,
				currIdx: idx,
				totalMeetingsInTab,
				conversation,
				targetProfile,
				orgContact,
				isLoading: false,
				tabKey,
			});
		} catch (e) {
			setOpenMeetingData(undefined);
			notificationManager.showErrorNotification({
				message: 'There was an issue viewing your meeting details',
			});
		}
	}

	function onDenyMeeting(connectionId: string) {
		onUpdateDetectedMeeting(false, connectionId).then(() => {
			onRefreshMeetingsBookedCount();
		});
	}
	function onConfirmMeeting(connectionId: string) {
		onUpdateDetectedMeeting(true, connectionId).then(() => {
			onRefreshMeetingsBookedCount();
		});
	}

	function onCloseDrawer() {
		if (isNil(openMeetingData)) return;
		onUpdateOnContactDrawerClose(openMeetingData.tabKey).then(() => {
			onRefreshMeetingsBookedCount();
		});
		setOpenMeetingData(undefined);
	}

	// Determine if the user should be shown the team member filter
	const { currentAccount } = useTeamsPageSideBarContext();
	const isTeamsAccount = currentAccount?.isTeamAccount ?? false;
	const hasTeamMemberAccess = RolesWithTeamMemberAccess.some((element) =>
		activeMember?.orgRoles.includes(element)
	);
	const isTeamMemberFilterEnabled = isTeamsAccount && hasTeamMemberAccess;

	return (
		<MeetingsBookedContext.Provider
			value={{
				...DEFAULT_MEETINGS_BOOKED_CONTEXT,
				teamMemberFilterEnabled: isTeamMemberFilterEnabled,
				onDenyMeeting,
				onConfirmMeeting,
				campaignOptions,
				teamMemberOptions,
				activeFilter: {
					teamMembers: selectedTeamMembers,
					campaigns: selectedCampaigns,
					dateRange: selectedDateRange,
				},
				onClearFilters,
				onLoadPage,
				onCampaignFilterChange,
				onTeamMemberFilterChange,
				onViewConversation,
				onViewPersonalizedInsights,
				onUpdatePageSize,
				aiDetectedMeetings,
				confirmedMeetings,
				confirmedPageSize,
				detectedPageSize,
				isLoadingConfirmed,
				isLoadingDetected,
				isInitializingData,
				isClearFiltersEnabled,
				onUpdateDateRange,
			}}
		>
			<MeetingsBookedPage />
			{!isNil(activeMember) && !isNil(openMeetingData) && (
				<ContactDrawerV2Smart
					onGoToThread={onGoToThread}
					onClose={onCloseDrawer}
					currConversationIdx={openMeetingData.currIdx}
					totalConversations={openMeetingData.totalMeetingsInTab}
					isOpen
					contactId={openMeetingData.meeting.contactId}
					isLoading={openMeetingData.isLoading}
					conversation={openMeetingData.conversation}
					targetProfile={openMeetingData.targetProfile}
					orgMemberId={openMeetingData.meeting.orgMemberId}
					orgContact={openMeetingData.orgContact}
					onMeetingBookedUpdated={partial(onMarkMeetingUpdated, openMeetingData.tabKey)}
				/>
			)}
		</MeetingsBookedContext.Provider>
	);
}
