import { Button, Flex, Table, Tooltip } from 'antd';
import { RightOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Meeting, PaginatedMeetingResponse } from '../types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TablePaginationConfig } from 'antd/lib';
import styles from './meetingTables.module.less';
import debounce from 'lodash/debounce';
import { MIN_TABLE_WIDTH, VIRTUAL_SCROLL_OFFSET } from './constants';
import { isEmpty, isNil } from 'lodash';
import notificationManager from '@copilot/common/utils/notificationManager';
import { MEETINGS_BOOKED_VIEW_INSIGHTS_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';
import { AiButton } from '@copilot/common/components/ai/aiButton/aiButton';
import { getCommunicationStyleDisplayText } from '@copilot/common/components/drawer/wrappers/contact/personalizedInsightsSectionV2/personalizedInsightsSectionV2Helpers';
import {
	CommunicationStyle,
	isCommunicationStyle,
} from '../../personalizedInsightsV2/common/personalizedInsightsV2Types';
import { CommunicationStyleDrawer } from '../communicationStyleDrawer';
import { ContactAvatar } from '@copilot/common/components/componentModels/activities/conversationWrapper/item/contact-avatar';

type BaseMeetingTableProps = {
	enablePersonalizedInsights?: boolean;
	leadingColumn: ColumnType<Meeting>;
	data: PaginatedMeetingResponse;
	onPageChange: (targetPage: number, pageSize: number) => void;
	onViewConversation: (meeting: Meeting, idx: number) => Promise<void>;
	onViewPersonalizedInsights?: (meeting: Meeting) => Promise<void>;
	onPageSizeChange: (pageSize: number) => void;
	pageSize: number;
	isExternallyLoading?: boolean;
	viewPortHeight: number;
};
/**
 * Base meetingsBookedTable, contains common elements and logic shared between detected and confirmed meeting tables
 * @returns
 */
export default function BaseMeetingsTable({
	leadingColumn,
	enablePersonalizedInsights = false,
	data: externalData,
	onPageChange,
	onViewConversation,
	onViewPersonalizedInsights: handleViewPersonalizedInsights,
	onPageSizeChange,
	pageSize,
	isExternallyLoading = false,
	viewPortHeight,
}: BaseMeetingTableProps) {
	const [dimensions, setDimensions] = useState({ x: 0, y: 0 });
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [loadingPersonalizedInsightsId, setLoadingPersonalizedInsightsId] = useState('');
	const [data, setData] = useState(externalData);
	const { isNewPersonalizedInsightsFeatureEnabled } = useFeatureFlags();
	const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>(undefined);
	const selectedStyle = selectedMeeting?.communicationStyles?.styles?.[0]?.style;

	//slightly buffer the data updates so our table doesn't flicker between empty -> loaded all the time
	useEffect(() => {
		if (!isExternallyLoading) {
			setData(externalData);
		}
	}, [externalData]);

	useEffect(() => {
		updateDimensions();
	}, [viewPortHeight]);

	/**
	 * Handler for adjusting the dimensions for the table, needed to support virtual scrolling
	 */
	function updateDimensions() {
		if (wrapperRef.current) {
			const { width, height } = wrapperRef.current?.getBoundingClientRect();
			setDimensions({
				x: Math.max(width, MIN_TABLE_WIDTH),
				y: Math.max(height - VIRTUAL_SCROLL_OFFSET, 0),
			});
		}
	}

	/**
	 * Debounced version of dimension update function
	 */
	const debouncedUpdateDimension = useCallback(debounce(updateDimensions, 200), [
		updateDimensions,
	]);

	useEffect(() => {
		// Update dimensions on window resize
		window.addEventListener('resize', debouncedUpdateDimension);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', debouncedUpdateDimension);
		};
	}, []);

	/**
	 * Catch all handler for table changes (filters, pages, page sizes, sorts)
	 * @param pagination
	 */
	function onTableChange(pagination: TablePaginationConfig) {
		if (pageSize !== pagination?.pageSize && typeof pagination?.pageSize === 'number') {
			onPageSizeChange(pagination.pageSize);
			onPageChange(1, pagination.pageSize);
		} else {
			onPageChange(pagination.current ?? 1, pageSize);
		}
	}

	function onViewPersonalizedInsights(meeting: Meeting) {
		if (!isNil(handleViewPersonalizedInsights)) {
			setLoadingPersonalizedInsightsId(meeting.id);
			handleViewPersonalizedInsights(meeting)
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'There was an issue viewing personalized insights',
					});
				})
				.finally(() => {
					setLoadingPersonalizedInsightsId('');
				});
		}
	}

	function onSeeMore() {
		if (!isNil(selectedMeeting)) {
			onViewPersonalizedInsights(selectedMeeting);
		}
	}

	function getTableColumns() {
		return [
			leadingColumn,
			{
				title: 'Name',
				dataIndex: 'name',
				render: (name: string) => (
					<div className={styles.cellTextWrapper} title={name}>
						<Flex gap={8} align={'center'}>
							<ContactAvatar contactName={name} type={'message'} />
							{name}
						</Flex>
					</div>
				),
				width: '12%',
			},

			{
				title: 'Job title',
				dataIndex: 'title',
				render: (jobTitle: string) => (
					<div className={styles.cellTextWrapper} title={jobTitle}>
						{jobTitle}
					</div>
				),
				width: '15%',
			},
			{
				title: 'Company',
				dataIndex: 'company',
				render: (company: string) => (
					<div className={styles.cellTextWrapper} title={company}>
						{company}
					</div>
				),
				width: '10%',
			},
			{
				title:
					isNewPersonalizedInsightsFeatureEnabled && enablePersonalizedInsights
						? 'Personalized insights'
						: '',
				dataIndex: '',
				render: (meeting: Meeting) => {
					const communicationStyle = meeting.communicationStyles?.styles?.[0]?.style;
					return (
						<Flex
							gap={8}
							justify={
								isNewPersonalizedInsightsFeatureEnabled &&
								enablePersonalizedInsights
									? 'space-between'
									: 'flex-end'
							}
							className={styles.actionWrapper}
						>
							{enablePersonalizedInsights && (
								<>
									{isNewPersonalizedInsightsFeatureEnabled ? (
										<>
											{isNil(communicationStyle) &&
											(meeting.communicationStyles?.lastUpdated ?? 0) ===
												0 ? (
												<AiButton
													className={styles.personalizedInsightsButton}
													isLoading={
														loadingPersonalizedInsightsId === meeting.id
													}
													onClick={(e) => {
														e.stopPropagation();
														if (isNil(communicationStyle)) {
															onViewPersonalizedInsights?.(meeting);
														} else {
															setSelectedMeeting(meeting);
														}
													}}
												>
													{isNil(communicationStyle)
														? 'Request insights'
														: `How to Talk to a ${getCommunicationStyleDisplayText(
																communicationStyle as CommunicationStyle
														  )}`}
												</AiButton>
											) : (
												<Flex gap={8} align={'center'}>
													Communication style undetermined
													<Tooltip
														overlayClassName={styles.helpToolTip}
														placement="bottomLeft"
														title={
															'We need more examples of this connection’s communication style. Once enough data is available, their style and personalized insights will be shown.'
														}
													>
														<QuestionCircleOutlined
															className={styles.helpIcon}
														/>
													</Tooltip>
												</Flex>
											)}
										</>
									) : (
										<Button
											loading={loadingPersonalizedInsightsId === meeting.id}
											onClick={(e) => {
												e.stopPropagation();
												onViewPersonalizedInsights?.(meeting);
											}}
											data-tracking-id={
												MEETINGS_BOOKED_VIEW_INSIGHTS_TRACKING_ID
											}
										>
											Personalized insights
										</Button>
									)}
								</>
							)}
							<RightOutlined />
						</Flex>
					);
				},
				width: '20%',
			},
		];
	}

	return (
		<div className={styles.tableWrapper} ref={wrapperRef}>
			<Table
				dataSource={data.content}
				loading={{
					spinning: isExternallyLoading,
					indicator: <LoadingOutlined className={styles.tableLoader} />,
				}}
				virtual
				scroll={dimensions}
				pagination={{
					pageSize,
					total: data.count,
					current: data.pages.current + 1,
				}}
				onRow={(record: Meeting, idx: number | undefined) => {
					return {
						onClick: () => {
							onViewConversation(record, idx ?? 0);
						},
					};
				}}
				onChange={(p) => void onTableChange(p)}
				columns={getTableColumns()}
			/>
			<CommunicationStyleDrawer
				communicationStyle={isCommunicationStyle(selectedStyle) ? selectedStyle : undefined}
				onSeeMore={onSeeMore}
				onClose={() => setSelectedMeeting(undefined)}
				loadingInsights={!isEmpty(loadingPersonalizedInsightsId)}
			/>
		</div>
	);
}
