import { Menu } from 'antd';
import { getInsightsCopy } from './constants';
import { CommunicationStyle } from '../../common/personalizedInsightsV2Types';
import { MessagingEffectivelySection } from './messageEffectivelySection';
import { MeetingStrategicallySection } from './meetStrategicallySection';
import { FollowUpSection } from './folllowUpSection';
import styles from './body.module.less';
import { MenuInfo } from 'rc-menu/lib/interface';
import TakeAwaysCard from './takeAwaysCard';
import { MessageOutlined, ScheduleOutlined, NotificationOutlined } from '@ant-design/icons';

type InsightsProps = {
	communicationStyle: CommunicationStyle;
};

/**
 * Body content for the personalized insights page
 * @param param0
 * @returns
 */
export default function InsightsBody({ communicationStyle }: InsightsProps) {
	const { messaging, meeting, following } = getInsightsCopy(communicationStyle);
	function onClick(i: MenuInfo) {
		const element = document.getElementById(i.key);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}
	return (
		<div className={styles.bodyWrapper}>
			<div className={styles.bodyContainer}>
				<Menu
					onClick={onClick}
					className={styles.menu}
					mode="inline"
					defaultSelectedKeys={['#message-effectively']}
					items={[
						{
							key: '#message-effectively',
							label: 'Message Effectively',
							icon: <MessageOutlined />,
						},
						{
							key: '#meet-strategically',
							label: 'Meet Strategically',
							icon: <ScheduleOutlined />,
						},
						{
							key: '#follow-up-for-success',
							label: 'Follow Up for Success',
							icon: <NotificationOutlined />,
						},
					]}
				/>
				<div className={styles.sectionWrapper}>
					<MessagingEffectivelySection
						data={messaging}
						communicationStyle={communicationStyle}
					/>
					<MeetingStrategicallySection
						data={meeting}
						communicationStyle={communicationStyle}
					/>
					<FollowUpSection data={following} communicationStyle={communicationStyle} />
					<div className={styles.mobileTakeAwaysSection}>
						<TakeAwaysCard communicationStyle={communicationStyle} />
					</div>
				</div>
				<div className={styles.takeAwaysSection}>
					<TakeAwaysCard communicationStyle={communicationStyle} />
				</div>
			</div>
		</div>
	);
}
