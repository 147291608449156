import { CONFIRMED_MEETINGS_TAB_KEY } from '../constants';
import useMeetingsBookedContext from '../context/useMeetingsBookedContext';
import BaseMeetingsTable from './baseMeetingsTable';
import partial from 'lodash/partial';
import moment from 'moment';
import isNumber from 'lodash/isNumber';

type ConfirmedMeetingsTableProps = { viewPortHeight: number };
/**
/**
 * Table of meetings confirmed/marked by the user
 * @returns
 */
export default function ConfirmedMeetingsTable({ viewPortHeight }: ConfirmedMeetingsTableProps) {
	const {
		confirmedMeetings,
		onLoadPage,
		onViewConversation,
		onViewPersonalizedInsights,
		onUpdatePageSize,
		confirmedPageSize,
		isLoadingConfirmed,
	} = useMeetingsBookedContext();

	return (
		<BaseMeetingsTable
			viewPortHeight={viewPortHeight}
			isExternallyLoading={isLoadingConfirmed}
			data={confirmedMeetings}
			pageSize={confirmedPageSize}
			onPageSizeChange={partial(onUpdatePageSize, CONFIRMED_MEETINGS_TAB_KEY)}
			onPageChange={partial(onLoadPage, CONFIRMED_MEETINGS_TAB_KEY)}
			enablePersonalizedInsights
			onViewConversation={partial(onViewConversation, CONFIRMED_MEETINGS_TAB_KEY)}
			onViewPersonalizedInsights={onViewPersonalizedInsights}
			leadingColumn={{
				title: 'Meeting booked on',
				dataIndex: 'meetingConfirmationDate',
				render: (date) => {
					if (!isNumber(date)) return '';
					return moment(date).format('MM/DD/YYYY');
				},
				width: '12%',
			}}
		/>
	);
}
