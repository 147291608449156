// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dvmj2CEnXCMT7K8YjBRS .copilot-drawer-header-title {\n  padding: 16px;\n}\n.zjDTZtpMDDA16XPN8a0p {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 32px;\n}\n.Z92lXgVTufhSkteFa391 {\n  width: 230px;\n}\n.SpIs1PR6GsvPPlHTeCzK {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.SpIs1PR6GsvPPlHTeCzK.zpjVo8e1gMu95xDXThkv {\n  color: #002c8c;\n}\n.SpIs1PR6GsvPPlHTeCzK.R2O218ce4yERWF6M9tzG {\n  color: #00474f;\n}\n.SpIs1PR6GsvPPlHTeCzK.uxLnpadxTIvNJhHLNogw {\n  color: #873800;\n}\n.SpIs1PR6GsvPPlHTeCzK.Be3HiWk37QIphuFIVIwX {\n  color: #531dab;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/meetingsBooked/communicationStyleDrawer.module.less"],"names":[],"mappings":"AACA;EAEE,aAAA;AADF;AAKA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AAHD;AAMA;EACC,YAAA;AAJD;AAOA;EACC,aAAA;EACA,mBAAA;EACA,SAAA;AALD;AAMC;EACC,cAAA;AAJF;AAMC;EACC,cAAA;AAJF;AAMC;EACC,cAAA;AAJF;AAMC;EACC,cAAA;AAJF","sourcesContent":["@import '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2Variables.less';\n.communicationStyleDrawer {\n\t:global(.copilot-drawer-header-title) {\n\t\tpadding: 16px;\n\t}\n}\n\n.sectionWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\trow-gap: 32px;\n}\n\n.seeMoreButton {\n\twidth: 230px;\n}\n\n.titleWrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 10px;\n\t&.director {\n\t\tcolor: @director-dark;\n\t}\n\t&.relator {\n\t\tcolor: @relator-dark;\n\t}\n\t&.socializer {\n\t\tcolor: @socializer-dark;\n\t}\n\t&.thinker {\n\t\tcolor: @thinker-dark;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communicationStyleDrawer": "dvmj2CEnXCMT7K8YjBRS",
	"sectionWrapper": "zjDTZtpMDDA16XPN8a0p",
	"seeMoreButton": "Z92lXgVTufhSkteFa391",
	"titleWrapper": "SpIs1PR6GsvPPlHTeCzK",
	"director": "zpjVo8e1gMu95xDXThkv",
	"relator": "R2O218ce4yERWF6M9tzG",
	"socializer": "uxLnpadxTIvNJhHLNogw",
	"thinker": "Be3HiWk37QIphuFIVIwX"
};
export default ___CSS_LOADER_EXPORT___;
