import { Card, Typography } from 'antd';
import styles from './sectionCard.module.less';
import { CommunicationStyle } from '../../../common/personalizedInsightsV2Types';
import { getCommunicationClass } from '../util';

type SectionCardProps = {
	isSubSection?: boolean;
	title?: JSX.Element;
	children: JSX.Element | JSX.Element[];
	communicationStyle?: CommunicationStyle;
	usesSection?: boolean;
};
/**
 * Card for sections and subsections, supporting a themed title or no title
 * @param param0
 * @returns
 */
export function SectionCard({
	isSubSection = false,
	title,
	children,
	communicationStyle,
	usesSection = false,
}: SectionCardProps) {
	const childrenArray = Array.isArray(children) ? children : [children];

	return (
		<Card
			className={[
				styles.sectionCard,
				isSubSection ? styles.secondary : styles.primary,
				usesSection ? styles.usesSection : undefined,
				getCommunicationClass(communicationStyle, styles),
			].join(' ')}
			title={
				title != undefined ? (
					<Typography.Title level={4} className={styles.sectionCardTitle}>
						{title}
					</Typography.Title>
				) : undefined
			}
		>
			{childrenArray.map((c) => c)}
		</Card>
	);
}
