import { Typography, Button, Spin, Flex, Tag } from 'antd';
import { Insights } from '../../common/personalizedInsightsV2Types';
import { getHeaderContent } from './constants';
import styles from './header.module.less';
import { getCommunicationClass } from '../body/util';
import isEmpty from 'lodash/isEmpty';
import { LinkedinFilled, ExportOutlined, LoadingOutlined } from '@ant-design/icons';
import CommStyleCard from './commStyleCard';
import { generateLinkedInProfileUrl } from '@copilot/common/utils/linkedinProfile/constant';
import isNil from 'lodash/isNil';
import { Icon } from '@iconify/react';
import { aiIcon } from '@copilot/common/constant/icons';
import { getCommunicationStyleDescriptorTags } from '../../common/personalizedInsightsV2HelperFunctions';
import { CommunicationStyleIcon } from '@copilot/common/components/ai/personalizedInsights/communicationStyleIcon';
import { getCommunicationStyleDisplayText } from '@copilot/common/components/drawer/wrappers/contact/personalizedInsightsSectionV2/personalizedInsightsSectionV2Helpers';

type InsightsHeaderProps = {
	insights: Insights;
	communicationStyleHelpArticleUrl?: string;
	remainingInsightsCredits?: number;
	creditCountFailed: boolean;
};

/**
 * Header section for the personalized insights page
 * @param param0
 * @returns
 */
function InsightsHeader({
	insights,
	communicationStyleHelpArticleUrl,
	remainingInsightsCredits,
	creditCountFailed,
}: InsightsHeaderProps) {
	const { commTypeTitle } = getHeaderContent(insights);
	const { commStyle, liAvatarUrl, title, firstName, lastName, alias, secondaryCommStyle } =
		insights;

	const hasAvatar = !isEmpty(liAvatarUrl);
	const hasTitle = !isEmpty(title);

	function onExportClick() {
		window.print();
	}

	function openCommStyleHelpArticle() {
		window.open(communicationStyleHelpArticleUrl);
	}

	const onCommStyleCTAClick = isEmpty(communicationStyleHelpArticleUrl)
		? undefined
		: openCommStyleHelpArticle;

	return (
		<>
			{!creditCountFailed && (
				<div
					className={[styles.tokenBanner, getCommunicationClass(commStyle, styles)].join(
						' '
					)}
				>
					<Typography.Text className={styles.tokenBannerText}>
						<Typography.Text className={styles.tokenCount}>
							<Icon icon={aiIcon} className={styles.tokenIcon} />
							{isNil(remainingInsightsCredits) ? (
								<Spin
									indicator={
										<LoadingOutlined
											className={styles.tokenIcon}
											style={{ fontSize: 16 }}
											spin
										/>
									}
								/>
							) : (
								remainingInsightsCredits
							)}{' '}
							more insights
							<Icon icon={aiIcon} className={styles.tokenIcon} />
						</Typography.Text>
						available this month
					</Typography.Text>
				</div>
			)}
			<div
				className={[styles.headerWrapper, getCommunicationClass(commStyle, styles)].join(
					' '
				)}
			>
				<div className={styles.headerCircle}></div>
				<div className={styles.header}>
					{hasAvatar && (
						<div className={styles.headerAvatar}>
							<img src={liAvatarUrl} alt="profile portrait" />
						</div>
					)}
					<div className={!hasAvatar ? styles.noAvatar : ''}>
						<div className={styles.headerTitleWrapper}>
							<div>
								<div className={styles.headerName}>
									<Typography.Title level={3}>
										{firstName} {lastName}
									</Typography.Title>
									{alias ? (
										<Button
											icon={<LinkedinFilled />}
											href={generateLinkedInProfileUrl(alias)}
											target="_blank"
										/>
									) : null}
								</div>
								{hasTitle ? (
									<Typography.Paragraph className={styles.headerSubtitle}>
										{title}
									</Typography.Paragraph>
								) : null}
							</div>
						</div>

						<Typography.Paragraph className={styles.commStyleLeadIn}>
							Communication style is a:
						</Typography.Paragraph>
						<Flex align="center" gap={16}>
							<CommunicationStyleIcon style={commStyle} size={32} />
							<Typography.Title level={1} className={styles.headerTitle}>
								{commTypeTitle}
							</Typography.Title>
						</Flex>
						{!isNil(secondaryCommStyle) && (
							<div
								className={[
									getCommunicationClass(secondaryCommStyle, styles),
									styles.secondaryStyleWrapper,
								].join(' ')}
							>
								<Tag>
									<CommunicationStyleIcon style={secondaryCommStyle} size={16} />
									Secondary Style:{' '}
									{getCommunicationStyleDisplayText(secondaryCommStyle)}
								</Tag>
							</div>
						)}

						<div className={styles.headerTagWrapper}>
							{getCommunicationStyleDescriptorTags(commStyle)}
						</div>
					</div>
					<div className={styles.commStyleSection}>
						<CommStyleCard onCTAClick={onCommStyleCTAClick} />
					</div>
				</div>
			</div>
			<div className={styles.mobileCommStyleSection}>
				<CommStyleCard onCTAClick={onCommStyleCTAClick} />
			</div>
			<div className={styles.exportWrapper}>
				<Button className={styles.exportButton} onClick={onExportClick}>
					<ExportOutlined />
					Export
				</Button>
			</div>
		</>
	);
}

export default InsightsHeader;
