// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d0OEjI1vRobLp8qmj3ij {\n  height: 100vh;\n}\n.q5NMfvo2rNutstR8WQVi {\n  border-right: 1px solid #f5f5f5;\n  min-height: 100%;\n  font-weight: 700;\n  overflow-y: auto;\n}\n.RILs4ay2E968YVLezHi7 {\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/layouts/headerSider/headerSider.module.less"],"names":[],"mappings":"AAEA;EACC,aAAA;AADD;AAIA;EACC,+BAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAFD;AAKA;EACC,gBAAA;AAHD","sourcesContent":["@import '@copilot/styles/antd-variables.less';\n\n.headerSiderWrapper {\n\theight: 100vh;\n}\n\n.siderWrapper {\n\tborder-right: 1px solid @Gray-3;\n\tmin-height: 100%;\n\tfont-weight: 700;\n\toverflow-y: auto;\n}\n\n.contentWrapper {\n\toverflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerSiderWrapper": "d0OEjI1vRobLp8qmj3ij",
	"siderWrapper": "q5NMfvo2rNutstR8WQVi",
	"contentWrapper": "RILs4ay2E968YVLezHi7"
};
export default ___CSS_LOADER_EXPORT___;
