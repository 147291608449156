import { Card, Typography } from 'antd';
import styles from './body.module.less';
import { CommunicationStyle } from '../../common/personalizedInsightsV2Types';
import { ContentWithTitle } from './types';

/**
 * Returns a grid section for a point card
 * @param item
 * @param idx
 * @returns
 */
export function getPointCardSection(item: ContentWithTitle, idx: number) {
	return (
		<Card.Grid key={item.title} hoverable={false} className={styles.pointCardSection}>
			<Typography.Title level={5} className={styles.cardPointTitle}>
				{idx + 1}. {item.title}:
			</Typography.Title>
			<Typography.Paragraph>{item.content}</Typography.Paragraph>
		</Card.Grid>
	);
}

/**
 * Util to get the communication style css class from a css module
 * @param communicationStyle
 * @param stylesObject
 * @returns
 */
export function getCommunicationClass(
	communicationStyle: CommunicationStyle | undefined,
	stylesObject: any
) {
	if (communicationStyle == undefined) {
		return '';
	}
	return stylesObject[communicationStyle];
}
