import { NotificationOutlined } from '@ant-design/icons';
import { SectionCard } from '../sectionCard';
import { CommunicationStyle } from '../../../common/personalizedInsightsV2Types';
import { getPointCardSection } from '../util';
import { ContentWithTitle } from '../types';

type FollowUpSectionProps = {
	data: ContentWithTitle[];
	communicationStyle: CommunicationStyle;
};
/**
 * Section describing effective followup protocols for a connection
 * @param param0
 * @returns
 */
export function FollowUpSection({ data, communicationStyle }: FollowUpSectionProps) {
	return (
		<div id="#follow-up-for-success">
			<SectionCard
				title={
					<>
						<NotificationOutlined /> Follow Up for Success
					</>
				}
				communicationStyle={communicationStyle}
			>
				<SectionCard isSubSection usesSection>
					{data.map((item, idx) => getPointCardSection(item, idx))}
				</SectionCard>
			</SectionCard>
		</div>
	);
}
