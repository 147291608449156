import { Button, Drawer } from 'antd';
import { CommunicationStyle } from '../personalizedInsightsV2/common/personalizedInsightsV2Types';
import { getInsightsCopy } from '../personalizedInsightsV2/components/body/constants';
import { MeetingStrategicallySection } from '../personalizedInsightsV2/components/body/meetStrategicallySection';
import styles from './communicationStyleDrawer.module.less';
import { SectionCard } from '../personalizedInsightsV2/components/body/sectionCard';
import { DoubleRightOutlined } from '@ant-design/icons';
import { getCommunicationStyleDisplayText } from '@copilot/common/components/drawer/wrappers/contact/personalizedInsightsSectionV2/personalizedInsightsSectionV2Helpers';
import isNil from 'lodash/isNil';
import { AiCircleIcon } from '@copilot/common/components/ai/aiCircleIcon/aiCircleIcon';

type CommunicationStyleDrawerProps = {
	communicationStyle?: CommunicationStyle;
	onSeeMore: () => void;
	onClose: () => void;
	loadingInsights: boolean;
};

/**
 * Drawer for displaying tips for conversing with a specific communication style
 * @param param0
 * @returns
 */
export function CommunicationStyleDrawer({
	communicationStyle,
	onSeeMore,
	onClose,
	loadingInsights,
}: CommunicationStyleDrawerProps) {
	if (isNil(communicationStyle)) {
		return null;
	}
	const { meeting } = getInsightsCopy(communicationStyle);
	return (
		<Drawer
			open={true}
			onClose={onClose}
			title={
				<div className={[styles.titleWrapper, styles[communicationStyle]].join(' ')}>
					<AiCircleIcon />
					Personalized Insights - How to prep to talk to a{' '}
					{getCommunicationStyleDisplayText(communicationStyle)}
				</div>
			}
			className={styles.communicationStyleDrawer}
			width={724}
		>
			<div className={styles.sectionWrapper}>
				<SectionCard
					title={
						<>
							<DoubleRightOutlined /> What are Communication Styles?
						</>
					}
					usesSection
					isSubSection
				>
					<>
						Dr. Tony Alessandra's communication style framework categorizes behavior
						into four distinct styles: Directors, Socializers, Thinkers, and Relators.
						This model is structured on two axes: one ranging from 'Faster and Direct'
						to 'Slower and Indirect,' and the other from 'Guarded and Task-Focused' to
						'Open and People-Focused.' Each quadrant represents a unique communication
						style, helping individuals understand and adapt their interactions for more
						effective communication.
					</>
				</SectionCard>
				<MeetingStrategicallySection
					data={meeting}
					communicationStyle={communicationStyle}
					wrapInSectionCard={false}
				/>

				<Button
					type="primary"
					className={styles.seeMoreButton}
					onClick={onSeeMore}
					loading={loadingInsights}
				>
					See more communication tips
				</Button>
			</div>
		</Drawer>
	);
}
