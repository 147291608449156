import { useContext } from 'react';
import { TeamsPageSideBarContext } from './context/teamsPageSideBarContext';
import { MEETING_BOOKED_COUNT_REFRESH_CALLBACK } from './context/constants';

/**
 * Facade hook layer for interacting with the pageSideBar context.
 * Registers callbacks and then executes them on request if they exist, and provides the shared state
 * @returns
 */
export default function useTeamsPageSideBarContext() {
	const { callBackMap, registerCallback, currentAccount, isLoadingCurrentAccount } = useContext(TeamsPageSideBarContext);

	//wrapper function for the meetings booked callback
	function onRefreshMeetingsBookedCount() {
		callBackMap[MEETING_BOOKED_COUNT_REFRESH_CALLBACK]?.();
	}

	return { 
		registerCallback, 
		onRefreshMeetingsBookedCount,
		currentAccount,
		isLoadingCurrentAccount,
	};
}
